// External imports
import { Container, Row, Col, Image } from "react-bootstrap";

// Internal imports
import { contacts } from "../../Constants/contacts.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles imports
import "../../Styles/Layout/Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid className="content">
        <Row>
          <Col>
            <Image 
              src="/logo3.png"
              width={200}
              height={80}
            />
          </Col>
          <Col>
            <Image 
              src="/logo1.png"
              width={120}
              height={100}
            />
          </Col>
          <Col>
            <Image 
              src="/feria.png"
              width={200}
              height={80}
            />
          </Col>
          <Col>
          <h2 className="section" style={{marginBottom: '10px'}}>Síguenos</h2>
            <div className="stack-contacts text-center">
              <div className="d-flex justify-content-center align-items-center">
                {contacts.map((contact, index) => (
                  <a 
                    key={index} 
                    href={contact.link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="mx-2" /* Clase de Bootstrap para espaciado horizontal */
                    style={{ textDecoration: 'none', color: '#FAFAFB' }} 
                  >
                    <FontAwesomeIcon icon={contact.logo} size="2x" />
                  </a>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;

