// Nosotros.jsx

import {  Card, Image, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import fondo from '../img/fondo.jpg';

// Internal imports
import { integrantes, mision, vision } from "../Constants/nosotros.js";

// Styles imports 
import "../Styles/Nosotros/Nosotros.scss";

export function Nosotros() {
  return (
    <>
      <Container className="generic-nosotros"
      >
        <Row className="gx-0 generic-mision-vision" style={{
          backgroundImage: `url(${fondo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }} >
          <Row className="title-mision-vision">
            <Image
              src="logo1.png"
              style={{ height: '250px', width: 'auto' }}
            />
          </Row>
          <Row className="gx-5" >
            <Col sm={12} md={6} className="col-description">
              <h2 style={{ fontWeight: 'bolder' }} >Misión</h2>
              <p>{mision}</p>
            </Col>
            <Col sm={12} md={6} className="col-description">
              <h2 style={{ fontWeight: 'bolder' }} >Visión</h2>
              <p>{vision}</p>
            </Col>
          </Row>
        </Row>
        <Row className="gx-0 generic-integrantes"
        >
          <Row>
            <h1 style={{ fontWeight: 'bolder', marginBottom: '20px' }}>Equipo SaberIA</h1>
            <hr
              style={{
                marginBottom: '30px',
                border: '2px solid #0e1d34' // Aplica el color y grosor del borde superior
              }}
            />
            <div className="integrantes">
              {integrantes.map((item, index) => (
                <Card className="card-integrante">
                  <Image
                    src={item.image}
                    roundedCircle
                    width={350}
                    fluid
                  />
                  <h2 className="text-name">{item.name}</h2>
                  <h3 className="text-role">{item.role}</h3>
                  <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" color="#FAFAFB" />
                  </a>
                </Card>

              ))}
            </div>
            <hr
              style={{
                marginTop: '30px',
                border: '2px solid #0e1d34' // Aplica el color y grosor del borde superior
              }}
            />
          </Row>
        </Row>
      </Container>
    </>
  );
}
