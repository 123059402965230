// Preguntas.scss

// External imports
import React from 'react';
import '../Styles/Hero.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';

// Internal imports
import { preguntas_func } from "../Constants/texts.js";

// Styles imports
import "../Styles/Hero.scss";

function Preguntas() {
  return (
    <Container fluid className="generic hero" >
      <Row className="gx-0"> 
        <Col xs={12} md={6} className="generic-image">
          <Image className="hero-img" src="students_2.png" rounded /> 
        </Col>
        <Col xs={12} md={6} className="generic-text">
          <h1>{preguntas_func.title}</h1>
          <p>{preguntas_func.description}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Preguntas;
