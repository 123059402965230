import React, { useState } from "react";
import {Map, Marker} from "@vis.gl/react-google-maps";

import "../../Styles/Map/CustomMap.scss";

const CustomMap = () => {
  // shows marker on London by default
  const [markerLocation,] = useState({
    lat: -33.490511,
    lng: -70.619019
  });

  return (
    <div className="map-container">
      <Map
        defaultZoom={13}
        defaultCenter={markerLocation}
        gestureHandling={"greedy"}
        disableDefaultUI
      >
        <Marker position={markerLocation} />
      </Map>
    </div>
  );
}

export default CustomMap;