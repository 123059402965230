// contacts.js
import { faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const contacts = [
  {
    label: 'Instagram',
    logo: faInstagram,
    link: 'https://www.instagram.com/eduvidia.app/',
  },
  {
    label: 'LinkedIn',
    logo: faLinkedin,
    link: 'https://www.linkedin.com/company/saberiacorp/',
  },
  {
    label: 'Youtube',
    logo: faYoutube,
    link: 'https://www.youtube.com/@EduvidIA',
  },
];
