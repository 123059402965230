// texts.js


export const summary_func = {
  title: 'Material educativo de forma rápida y eficiente',
  description: 'EduvidIA es una herramienta que acelera el proceso de creación de contenido educativo e interactivo para las salas de clases a lo largo de todo el país.',
}

export const whiteboard_func = {
  title: 'Videos didácticos procedurales',
  description: 'EduvidIA genera videos educativos de animación whiteboard en base a documentos entregados por profesores, utilizando el poder de la inteligencia artificial.',
}

export const preguntas_func = {
  title: 'Evaluaciones creadas al instante',
  description: 'EduvidIA genera preguntas que evalúan a los alumnos sobre los contenidos de los videos, registrando los resultados y permitiendo su análisis por los profesores.',
}


