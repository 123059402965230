// External imports
import React from 'react';
import '../Styles/Hero.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';

// Internal imports
import { whiteboard_func } from "../Constants/texts.js";

// Styles imports
import "../Styles/Hero.scss";

function Whiteboard() {
  return (
    <Container fluid className="generic funcion">
      <Row className="gx-0 d-flex align-items-center">
        {/* Para pantallas pequeñas, el texto se mostrará arriba y la imagen abajo */}

        <Col xs={12} md={6} className="generic-image">
          <Image className="hero-img" src="video_foto.png" rounded fluid />
        </Col>
        <Col xs={12} md={6} className="generic-text">
          <h1>{whiteboard_func.title}</h1>
          <p>{whiteboard_func.description}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Whiteboard;
