import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import './App.css';

import Layout from "./Components/Layout/Layout.jsx";
import { Home } from "./Pages/Home.jsx";
import { Nosotros } from "./Pages/Nosotros.jsx";
import { Contacto } from "./Pages/Contacto.jsx";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="Nosotros" element={<Nosotros />} />
            <Route path="Contacto" element={<Contacto />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
