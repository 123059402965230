// nosotros.js


export const integrantes = [
  {
    image: 'integrantes/jj.jpeg',
    name: 'Javier Jaure',
    role: 'Scrum Master',
    linkedin: 'https://www.linkedin.com/in/javier-jaure-silva-741229246/',
  },

  {
    image: 'integrantes/so.jpg',
    name: 'Sebastian Olivares',
    role: 'Product Owner',
    linkedin: 'https://www.linkedin.com/in/sebastianolicar/',
  },

  {
    image: 'https://drive.google.com/thumbnail?id=1nWvDN0j193e8IwbPTMHba6iIhEyQ4li5&sz=w500',
    name: 'Nicolas Barrera',
    role: 'Video y Animación',
    linkedin: 'https://www.linkedin.com/in/nicolas-barrera-932aa3303/',
  },

  {
    image: 'integrantes/jmg.jpeg',
    name: 'Jose Miguel González',
    role: 'Dev AI',
    linkedin: 'https://www.linkedin.com/in/jos%C3%A9-miguel-gonz%C3%A1lez-balmaceda-756803303/',
  },

  {
    image: 'integrantes/io.jpeg',
    name: 'Ignacio Ortiz',
    role: 'Marketing',
    linkedin: 'https://www.linkedin.com/in/ignacio-ortiz-valdebenito-97398a219/',
  },
]

export const mision = "Proporcionar tecnologías basadas en inteligencia artificial que ayuden a nuestros clientes a reducir el tiempo que invierten en realizar su trabajo y puedan disponer de más tiempo libre para dedicarlo a su vida personal y al disfrute de esta."

export const vision = "Ser una empresa de inteligencia artificial conocida a nivel nacional e internacional por el aporte en mejorar la vida de las personas a través de nuestros productos y que estos sean reconocidos por su calidad y confiabilidad."
