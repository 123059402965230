import Hero from "../Components/Hero.jsx";
import Whiteboard from "../Components/Whiteboard.jsx";
import Preguntas from "../Components/Preguntas.jsx";

// Styles imports
import "../Styles/Home.scss";


export function Home() {
  return(
  <div className="home">
    <Hero/> 
    <Whiteboard/>
    <Preguntas />
  </div>
  );
}
