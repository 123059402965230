// External imports
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { APIProvider } from "@vis.gl/react-google-maps";

// Internal imports
import CustomMap from '../Components/Map/CustomMap';

// Styles imports 
import '../Styles/Contacto/Contacto.scss';

export function Contacto() {
  return (
    <div>
      <Container className="generic-contacto">
        <Row>
          <Col>
            <h1 className="contacto-title">Contacto</h1>
            <h2 className="contacto-subtitle">
              Si quieres saber más sobre nosotros, puedes seguirnos en nuestras redes sociales.
            </h2>
          </Col>
        </Row>

        {/* Redes Sociales */}
        <Row className="social-media-row justify-content-center">
          <Col xs={12} md={4} className="text-center mb-3">
            <a 
              href="https://www.instagram.com/eduvidia.app/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-media-link"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" color="#0077b5" />
              <span className="social-media-name d-block">Instagram</span>
            </a>
          </Col>
          <Col xs={12} md={4} className="text-center mb-3">
            <a 
              href="https://www.linkedin.com/company/saberiacorp/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-media-link"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" color="#0077b5" />
              <span className="social-media-name d-block">LinkedIn</span>
            </a>
          </Col>
          <Col xs={12} md={4} className="text-center mb-3">
            <a 
              href="https://www.youtube.com/@EduvidIA" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-media-link"
            >
              <FontAwesomeIcon icon={faYoutube} size="2x" color="#0077b5" />
              <span className="social-media-name d-block">YouTube</span>
            </a>
          </Col>
        </Row>

        {/* Mapa */}
        <Row>
          <Col>
            <h2 className="contacto-subtitle">Visítanos en la Feria de Software este 15 de Noviembre del 2024</h2>
            <div className="map-container">
              <APIProvider key="API_KEY" apiKey={process.env.REACT_APP_API_KEY}>
                <CustomMap />
              </APIProvider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
