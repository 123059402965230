// // Layout.jsx

// External imports
import { Outlet } from "react-router-dom";

// Internal imports
import MainNavbar from "./MainNavbar.jsx";
import Footer from "./Footer.jsx";

// Styles imports
import "../../Styles/Layout/Layout.scss";

function Layout() {
  return (
    <>
      <MainNavbar/>
      <main className="main-content">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default Layout;


